.brandsContainer {
    height: 850px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #F5F5F5;
    padding: 5rem;
    flex-direction: column;
}

.brandsContainer h2 {
  color: #CE6A32;
  font-size: 2.5rem;
  margin-bottom: 5rem;
}

.item {
  display: flex;
  padding: 4rem;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.item img {
  width: 50px;
  height: 200px;
}

@media (max-width: 768px) {
    .brandsContainer {
        display: flex;
        flex-direction: column;
        height: 500px;
        width: 100%;
    }

    .brandsContainer h2 {
      color: #CE6A32;
      font-size: 1.5rem;
      margin: 0;
      width: 100%;
      text-align: center;
    }

    .item {
      padding: 2.3rem;
    }

    .item img {
      height: 60px;
    }

}
