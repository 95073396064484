.professionalComponent3 {
    height: 850px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0F131E;
    padding: 5rem;
}

.dsdms {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dsdms h5 {
  max-width: 60%;
  text-align: center;
  align-self: center;
  padding: 1rem;
  margin-bottom: 3rem;
}

.dsdms h2 {
    color: #CE6A32;
    font-size: 2.5rem;
}

@media (max-width: 768px) {
  .professionalComponent3 {
    height: auto;
    width: 100%;
    display: block;
}

.dsdms {
  display: flex;
}

.dsdms h5 {
  max-width: 100%;
  text-align: center;
  align-self: center;
}

.dsdms h2 {
  color: #CE6A32;
  font-size: 1.5rem;
  flex-wrap: wrap;
}
}
