.footer {
    background-color: #0F131E;
    padding: 2rem;
    text-align: center;
}

.footer div {
  margin-bottom: 10px;
}

.footer a {
  color: #FFF;
  font-size: 1.5rem;
  padding: 3px;
}

.textFooter {
    line-height: 1;
    color: #fff;
    font-size: 0.88rem;
}
