.cultura-header2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 5rem;
  background-color: #F0f0f0;
  color: #333333;
}

.logo-top {
  width: 200px;
}

.prestadorServicos {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;
    justify-items: center;
    grid-gap: 5rem;
    align-items: start;
    background: #F5F5F5;
    padding: 3% 15%;
}

.icons-container {
  width: 100%;
  height: 500px;
  background-image: url(../../../assets/bg_security.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

.cadastroServicos {
    text-align: center;
    padding: 1.5rem;
    margin-top: -10rem;
    background-color: #F5F5F5;
}

.cadastroServicos img {
    width: 7rem;
}

.cadastroServicos h3 {
    color: #333333;
    font-size: 1.1rem;
    padding-top: 1rem;
    font-weight: 600;
    line-height: normal;
}

.cadastroServicos p {
    color:#9d9d9d;
    font-size: 0.9rem;
    line-height: normal;
}

.pedidosClientes {
    text-align: center;
    padding: 1.5rem;
    margin-top: -10rem;
    background-color: #F5F5F5;
}

.pedidosClientes img {
    width: 7rem;
}

.pedidosClientes h3 {
    color: #333333;
    font-size: 1.1rem;
    padding-top: 1rem;
    font-weight: 600;
    line-height: normal;
}

.pedidosClientes p {
    color:#9d9d9d;
    font-size: 0.9rem;
    line-height: normal;
}

.liberePedido {
    text-align: center;
    padding: 1.5rem;
    margin-top: -10rem;
    background-color: #F5F5F5;
}

.liberePedido img {
    width: 7rem;
}

.liberePedido h3 {
    color: #333333;
    font-size: 1.1rem;
    font-weight: 600;
    padding-top: 1rem;
    line-height: normal;
}

.liberePedido p {
    color:#9d9d9d;
    font-size: 0.9rem;
    line-height: normal;
}

@media (max-width: 769px) {
    .prestadorServicos {
        display: flex;
        flex-direction: column;
    }

    .logo-top {
      width: auto;
    }

    .cultura-header2 h3 {
      color: #333333;
      font-size: 1rem;
      font-weight: 600;
      line-height: normal;
  }

  .cultura-header2 {
    padding: 2rem;
  }
}


