.serviceComponent {
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F5F5;
    padding: 0rem 5rem;
}

.textContainers {
  padding: 5rem 2rem;
  text-align: justify;
  color: #0F131E;
  max-width: 60%;
}

.textContainers p {
  color: #3C4962;
}

.form-container {
    padding: 4rem 3rem;
    background: #fff;
    align-items: center;
    justify-content: center;
    margin-top: -20%;
    min-height: 600px;
    min-width: 40%;
    margin-left: 3rem;
    z-index: 9999;
    box-shadow: 1px 1px 2px 2px #e8e8e8;
    font-family: 'Open Sans', sans-serif;

    color: #CE6A32;
}

 form .field {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.form-container h2 {
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 1.2rem;
  min-height: auto;
  font-weight: bold;
}

 form .field input[type=text],
 form .field input[type=email],
 form .field textarea,
 form .field input[type=number] {
  flex: 1;
  background: #F0F0F5;
  border: 0;
  padding: 16px 24px;
  font-size: 16px;
  color: #6C6C80;
}


 form .field input::placeholder, form .field textarea::placeholder {
  color: #a0a0a0;
  font-size: 0.8rem;
}

 form .field label {
  font-size: 14px;
  margin-bottom: 8px;
}

 form .field-check {
  flex-direction: row;
  align-items: center;
}

 form .field-check input[type=checkbox] {
  background: #F0F0F5;
}

 form .field-check label {
  margin: 0 0 0 8px;
}

 form button {
  width: 100%;
  height: 56px;
  background: #CE6A32;
  color: #FFF;
  font-weight: bold;
  font-size: 16px;
  border: 0;
  align-self: center;
  margin-top: 40px;
  transition: background-color 0.2s;
  cursor: pointer;
}

 form button:hover {
  background: #e2783b;
}

@media (max-width: 769px) {
  .textContainers {
    text-align: justify;
    color: #0F131E;
    padding: 1rem;
    max-width: 100%;
  }

  .serviceComponent {
        height: auto;
        display: flex;
        flex-direction: column;
        padding: 2rem 0rem;
  }

  .textButtonHeader span {
        padding-top: 10%;
  }

  .form-container {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    min-height: auto;
    min-width: 100%;
    padding: 1rem;
  }
}
