.professionalComponent {
    height: 850px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0F131E;
    padding: 5rem;
}

.title-priority h2 {
    color: #CE6A32;
    margin-bottom: 5rem;
    font-size: 2.4rem;
}

.professionalButtonsf {
  display: flex;
  width: 80%;
  justify-content: space-around;
  align-items: center;
  margin-top: 2.5rem;
}

.button-img2, .button-img3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background: #181616;
  position: relative;
  z-index: 5;
  padding: 6%;
  width: 400px;
  height: 300px;
  border-radius: 3rem;
  transition: 0.5s all;
}

.button-img2::after {
  border-radius: 3rem;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.4;
  z-index: -1;
  background-image: url(../../../assets/bg_header.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
}

.button-img3::after {
  border-radius: 3rem;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.3;
  z-index: -1;
  background-image: url(../../../assets/bg_performance.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
}

.button-img2:hover, .button-img3:hover {
  transform: scale(1.1);
}

.button-img-description {
  position: absolute;
  top: 2rem;
  left: 2rem;
  max-width: 70%;
}

.button-img-description h3 {
  color: #FFFFFF;
  text-transform: uppercase;
  margin-top: 2rem;
}

.button-img-description h5 {
  color: #FFFFFF;
}

@media (max-width: 768px) {
    .professionalComponent {
        display: flex;
        flex-direction: column;
        padding: 1rem 2rem;
    }

    .title-priority h2 {
      margin-bottom: 0;
      font-size: 2rem;
    }

    .professionalButtonsf {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .button-img2, .button-img3 {
      width: 320px;
      margin-bottom: 1rem;
    }

    .button-img2::after, .button-img3::after {
      width: 320px;
    }
}
