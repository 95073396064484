.secondHeader2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 700px;
  background: #181616;
  position: relative;
  z-index: 5;
  padding: 6%;
}

.secondHeader2::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0.2;
  z-index: -1;
  background-image: url(../../../assets/bg_table.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
}

.secondHeader2 img {
  height: 350px;
  width: 100%;
}

@media(max-width: 768px) {
  .secondHeader2 {
    height: 200px;
    width: 100%;
  }

  .secondHeader2 img {
    height: 100px;
    width: 100%;
  }
}
