.professionalComponent2 {
    height: 850px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0F131E;
    padding: 5rem;
}

.textButtonHeader h5 {
  max-width: 60%;
  text-align: center;
  align-self: center;
  padding: 1rem;
  margin-bottom: 3rem;
}

.textButtonHeader h2 {
    color: #CE6A32;
    font-weight: normal;
}

@media (max-width: 768px) {
    .professionalComponent2 {
        height: auto;
        width: 100%;
        display: block;
    }

    .textButtonHeader {
      display: flex;
    }

    .textButtonHeader h5 {
      max-width: 100%;
      text-align: center;
      align-self: center;
    }

    .textButtonHeader h2 {
      color: #CE6A32;
      font-size: 1.5rem;
      flex-wrap: wrap;
    }
}
