.secondHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 700px;
    background: #181616;
    position: relative;
    z-index: 5;
    padding: 6%;
}

.secondHeader::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0.2;
    z-index: -1;
    background-image: url(../../../assets/bg_header.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
}

#fdkj {
  color: #fff;
  font-size: 2rem;
  text-transform: uppercase;
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 400px;
  font-weight: normal;
}

.textButtonHeader {
    display: flex;
    flex-direction: column;
}

.textButtonHeader h2 {
    color: #232020;
    font-size: 2.5rem;
    line-height: 1.3em;
    font-weight: 700;
}

.textButtonHeader p {
    color: #232020;
    font-size: 17px;
    margin: 10px 0px 40px;
}

.buttonHeaderIndex button {
    width: 190px;
    background: none;
    color: rgb(35, 32, 32);
    border-radius: 50px;
    border-color: rgb(35, 32, 32);
    border-width: 1px;
    font-weight: 600;
    margin-right: 10px;
    height: 45px;
}

.buttonHeaderIndex button:hover {
    background: #232020;
    color: #fff;
}

.buttonHeaderIndex button i {
    color: #232020;
    padding: 10px;
}

.buttonHeaderIndex button i:hover {
    color: #fff;
}

.buttonHeaderIndex button:hover i {
    color: #fff;
}

@media (max-width: 769px) {
    .secondHeader {
        display: block;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 400px;
    }

    #fdkj {
      color: #fff;
      font-size: 1.5rem;
      text-transform: uppercase;
      font-weight: 200;
      position: relative;
      top: 7rem;
      left: 0;
      right: 0;
      text-align: center;
      width: 100%;
    }

    .logo-header {
      width: 80%;
    }
}
