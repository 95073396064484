.contatoComponent {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F5F5;
    padding: 3rem;
    text-align: center;
    position: relative;
    color: #0F131E;
}

.title-bold {
  font-weight: bold;
}

.cultura-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.logo-top {
  width: 200px;
}

.contatoComponent h1 {
  font-size: 3rem;
  max-width: 70%;
  text-align: start;
}

.textButtonContact h2 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.4rem;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  align-items: center;
}

.grid-container .driver {
  width: 500px;
}

.grid-container img {
  width: 300px;
}

.grid-container h4 {
  font-weight: bold;
  text-align: left;
  margin-bottom: 1.5rem;
}

.grid-container p {
  font-size: 1rem;
  text-align: left;
  margin: 0.8rem 0;
}

.grid-container .subtitle {
  font-size: 0.9rem;
  text-align: left;
  margin: 0.5rem 0;
}


.buttonContact {
    color: #fff;
    padding: 10px;
    width: 100%;
    border: none;
    border-radius: 40px;
    background: #CE6A32;
    font-weight: 700;
}

.buttonContact:hover {
    background: #CE6A32;
}

.textContact {
    padding-bottom: 1rem;
}

i.fas.fa-envelope {
    padding: 0 0.5rem;
}

@media(max-width: 768px) {
    .buttonContact button {
        width: 90%;
        margin: 0px;
    }

    .logo-top {
      width: 80px;
      height: 80px;
    }

    .contatoComponent {
       display: flex;
       padding: 2rem 1rem;
    }

    .contatoComponent h1 {
      font-size: 4rem;
      max-width: 100%;
    }

    .cultura-header h1 {
      font-size: 1.5rem;
    }

    .cultura-header h3 {
      font-size: 1rem;
      text-align: right;
      display: flex;
    }

    .cultura-header .logo-top {
      width: 80px;
      height: 50px;
    }

    .grid-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 0rem;
    }

    .grid-container .driver {
      width: 100%;
    }

    .grid-container img {
      width: 200px;
    }

    .grid-container .subtitle {
      text-align: justify;
      margin: 0;
    }
}
